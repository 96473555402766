<template>
  <v-container class="my-12">
    <h1 class="text-h4">Gestion des utilisateurs</h1>

    <!-- <v-btn @click="addOwnerTest">Test</v-btn> -->

    <div class="d-flex align-center mt-6">
      <v-autocomplete
        label="Ajouter un utilisateur"
        v-model="asyncUser"
        :items="asyncUsers"
        item-text="mail"
        return-object
        :loading="asyncUsersAreLoading"
        :search-input.sync="asyncSearch"
        auto-select-first
        clearable
        hide-no-data
        maxlength="20"
        :style="{ 'max-width': '320px' }"
        hide-details
        dense
        solo
      ></v-autocomplete>

      <v-btn
        color="primary"
        class="ml-2"
        @click="addUser"
        :loading="addUserIsLoading"
        :disabled="!asyncUser"
      >
        Ajouter
      </v-btn>
    </div>

    <v-card outlined class="mt-6">
      <v-toolbar flat>
        <template v-if="user">
          <v-btn icon @click="user = null">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title>
            {{ user.displayName }}

            <span class="text-overline error--text" v-if="checkUserIsAdmin">(Admin)</span>
            <span class="text-overline error--text" v-if="checkUserIsExt">(Externe)</span>
          </v-toolbar-title>
        </template>

        <template v-else>
          <v-toolbar-title>Utilisateurs</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Rechercher"
            single-line
            hide-details
          ></v-text-field>
        </template>
      </v-toolbar>

      <v-data-table
        v-if="!user"
        :headers="headers"
        :items="users"
        :loading="usersAreLoading"
        :search="search"
        @click:row="getUserGroups"
      >
        <template v-slot:item.action="{ item }">
          <v-icon color="error" small @click.stop="openDelDialog(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>

      <v-card-text v-else>
        <v-simple-table class="blue lighten-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-body-1 font-weight-bold text-decoration-underline">DAHU</th>

                <th
                  class="text-left"
                  v-for="dept in departments"
                  :key="dept.name"
                >
                  {{ dept.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="n in ['GES', 'DIR']"
                :key="n"
              >
                <td>{{ n === 'GES' ? 'Gestionnaire' : 'Directeur' }}</td>

                <td
                  v-for="dept in departments"
                  :key="dept.name"
                >
                  <v-simple-checkbox
                    :ripple="false"
                    :value="checkRightOwnership('DAHU', dept.name, n)"
                    @input="updateUserGroup('DAHU', dept.name, n, $event)"
                  ></v-simple-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-divider class="my-4"></v-divider>

        <v-simple-table class="blue lighten-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-body-1 font-weight-bold text-decoration-underline">ENF</th>

                <th
                  class="text-left"
                  v-for="dept in departments"
                  :key="dept.name"
                >
                  {{ dept.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="n in ['GES', 'DIR']"
                :key="n"
              >
                <td>{{ n === 'GES' ? 'Gestionnaire' : 'Directeur' }}</td>

                <td
                  v-for="dept in departments"
                  :key="dept.name"
                >
                  <v-simple-checkbox
                    :ripple="false"
                    :value="checkRightOwnership('ENF', dept.name, n)"
                    @input="updateUserGroup('ENF', dept.name, n, $event)"
                  ></v-simple-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-divider class="my-4"></v-divider>

        <v-simple-table class="blue lighten-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-body-1 font-weight-bold text-decoration-underline">ENF - V</th>

                <th
                  class="text-left"
                  v-for="dept in departments"
                  :key="dept.name"
                >
                  {{ dept.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="n in ['GES', 'DIR']"
                :key="n"
              >
                <td>{{ n === 'GES' ? 'Gestionnaire' : 'Directeur' }}</td>

                <td
                  v-for="dept in departments"
                  :key="dept.name"
                >
                  <v-simple-checkbox
                    :ripple="false"
                    :value="checkRightOwnership('ENFV', dept.name, n)"
                    @input="updateUserGroup('ENFV', dept.name, n, $event)"
                  ></v-simple-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-divider class="my-4"></v-divider>

        <v-simple-table class="blue lighten-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-body-1 font-weight-bold text-decoration-underline">ENR</th>

                <th
                  class="text-left"
                  v-for="dept in ['LOG', 'MOT']"
                  :key="dept"
                >
                  {{ dept }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Gestionnaire</td>

                <td
                  v-for="dept in ['LOG', 'MOT']"
                  :key="dept"
                >
                  <v-simple-checkbox
                    :ripple="false"
                    :value="checkRightOwnership('ENR', dept, 'GES')"
                    @input="updateUserGroup('ENR', dept, 'GES', $event)"
                  ></v-simple-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-divider class="my-4"></v-divider>

        <v-simple-table class="blue lighten-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-body-1 font-weight-bold text-decoration-underline">PSS</th>

                <th
                  class="text-left"
                  v-for="dept in departments"
                  :key="dept.name"
                >
                  {{ dept.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>GES</td>

                <td
                  v-for="dept in departments"
                  :key="dept.name"
                >
                  <v-simple-checkbox
                    :ripple="false"
                    :value="checkRightOwnership('PARA', dept.name, 'GES')"
                    @input="updateUserGroup('PARA', dept.name, 'GES', $event)"
                  ></v-simple-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-dialog
      v-if="userToDelete"
      v-model="delDial"
      persistent
      max-width="340"
    >
      <v-card>
        <v-card-title class="error white--text">
          Supprimer utilisateur
        </v-card-title>

        <v-card-text class="pt-5">
          Êtes-vous sûr de vouloir supprimer l'accès à l'application pour l'utilisateur
          <span class="font-weight-bold">{{ userToDelete.displayName }}</span>
          ?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-space-between">
          <v-btn
            text
            @click="closeDelDialog"
            :disabled="delLoading"
          >
            Annuler
          </v-btn>

          <v-btn
            text
            @click="deleteUser"
            :loading="delLoading"
            color="error"
          >
            Supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const axios = require('axios').default;
const debounce = require('lodash/debounce');

export default {
  data: () => ({
    headers: [
      { text: 'Nom complet', value: 'displayName' },
      { text: 'Adresse e-mail', value: 'mail' },
      {
        text: 'Action',
        value: 'action',
        filterable: false,
        sortable: false,
        align: 'right',
      },
    ],
    groupId: 'f19a65b9-7560-471c-b74d-ae2f564d6c9e',
    request: null,
    delDial: false,
    delLoading: false,
    departments: [
      { name: 'ABC', value: '' },
      { name: 'ART', value: '' },
      { name: 'SOC', value: '' },
      { name: 'MGT', value: '' },
      { name: 'PAR', value: '' },
      { name: 'PED', value: '' },
      { name: 'MOT', value: '' },
      { name: 'ECO', value: '' },
      { name: 'TEC', value: '' },
      { name: 'LOG', value: '' },
    ],
    userGroups: [],
    allGroups: [],

    users: [],
    user: null,
    usersAreLoading: false,
    search: null,

    asyncUsers: [],
    asyncUser: null,
    asyncUsersAreLoading: false,
    asyncSearch: '',

    addUserIsLoading: false,
    userToDelete: null,
  }),

  created() {
    this.getUsersAndGroups();
  },

  computed: {
    checkUserIsAdmin() {
      return this.userGroups.some((group) => group.displayName === 'APP.INS.ADMIN');
    },

    checkUserIsExt() {
      return this.userGroups.some((group) => group.displayName === 'APP.INS.EXT');
    },
  },

  methods: {
    async getUsersAndGroups() {
      await this.$store.dispatch('getAccessToken', true);
      try {
        this.usersAreLoading = true;
        const response = await Promise.all([this.getUsers(), this.getAllGroups()]);
        this.users = response[0].data.value;
        this.allGroups = response[1].data.value;
      } catch (error) {
        console.log(error);
      } finally {
        this.usersAreLoading = false;
      }
    },

    getUsers() {
      return this.$http.get(`https://graph.microsoft.com/v1.0/groups/${this.groupId}/members?$select=id,displayName,mail`);
    },

    getAllGroups() {
      const spId = '90b60d64-953a-4792-95fe-4eb4c6cd4384';
      return this.$http.get(`https://graph.microsoft.com/v1.0/servicePrincipals/${spId}/appRoleAssignedTo?$select=principalDisplayName,principalId&$top=999`);
    },

    async getUserGroups(user) {
      if (this.usersAreLoading) return;
      await this.$store.dispatch('getAccessToken', true);
      try {
        this.usersAreLoading = true;
        const response = await this.$http.get(`https://graph.microsoft.com/v1.0/users/${user.id}/memberOf/microsoft.graph.group?$search="displayName:APP.INS."&$select=displayName,id`, { headers: { ConsistencyLevel: 'eventual' } });
        this.userGroups = response.data.value;
        this.user = user;
      } catch (error) {
        console.log(error);
      } finally {
        this.usersAreLoading = false;
      }
    },

    async addUser() {
      await this.$store.dispatch('getAccessToken', true);
      try {
        this.addUserIsLoading = true;
        const data = { '@odata.id': `https://graph.microsoft.com/v1.0/directoryObjects/${this.asyncUser.id}` };
        await this.$http.post(`https://graph.microsoft.com/v1.0/groups/${this.groupId}/members/$ref`, data);
        this.users.push(this.asyncUser);
        // this.user = this.asyncUser;
        await this.getUserGroups(this.asyncUser);
        this.asyncUser = null;
      } catch (error) {
        console.log(error);
      } finally {
        this.addUserIsLoading = false;
      }
    },

    async updateUserGroup(formType, dept, userLevel, event) {
      await this.$store.dispatch('getAccessToken', true);
      const group = this.allGroups.find((groupp) => groupp.principalDisplayName === `APP.INS.${formType}.${dept}.${userLevel}`);
      if (event) {
        try {
          const data = {
            '@odata.id': `https://graph.microsoft.com/v1.0/directoryObjects/${this.user.id}`,
          };
          await this.$http.post(`https://graph.microsoft.com/v1.0/groups/${group.principalId}/members/$ref`, data);
          this.userGroups.push({ displayName: group.principalDisplayName, id: group.principalId });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          await this.$http.delete(`https://graph.microsoft.com/v1.0/groups/${group.principalId}/members/${this.user.id}/$ref`);
          const prout = this.userGroups.find((test) => test.id === group.principalId);
          const index = this.userGroups.indexOf(prout);
          this.userGroups.splice(index, 1);
        } catch (error) {
          console.log(error);
        }
      }
    },

    checkRightOwnership(formType, dept, userLevel) {
      return this.userGroups.some((group) => group.displayName === `APP.INS.${formType}.${dept}.${userLevel}`);
    },

    checkSearch: debounce(function (val) {
      if (!val || val.length < 4 || (this.asyncUser && this.asyncUser.mail === val)) {
        if (this.request) this.cancelReq();
        if (!val || val.length < 4) this.asyncUsers = [];
      } else {
        this.getAsyncUsers(val);
      }
    }, 1000),

    async getAsyncUsers(val) {
      await this.$store.dispatch('getAccessToken', true);
      try {
        this.asyncUsersAreLoading = true;
        if (this.request) this.cancelReq();
        const axiosSource = axios.CancelToken.source();
        this.request = { cancel: axiosSource.cancel };
        const response = await this.$http.get(`https://graph.microsoft.com/v1.0/users?$search="mail:${val}"&$select=mail,displayName,id`, { cancelToken: axiosSource.token, headers: { ConsistencyLevel: 'eventual' } });
        this.asyncUsers = response.data.value;
        this.request = null;
      } catch (error) {
        console.log(error);
      } finally {
        this.asyncUsersAreLoading = false;
      }
    },
    async deleteUser() {
      await this.$store.dispatch('getAccessToken', true);
      try {
        this.delLoading = true;
        await this.$http.delete(`https://graph.microsoft.com/v1.0/groups/${this.groupId}/members/${this.userToDelete.id}/$ref`);
        const index = this.users.findIndex((user) => user === this.userToDelete);
        this.users.splice(index, 1);
        this.closeDelDialog();
      } catch (error) {
        console.log(error);
      } finally {
        this.delLoading = false;
      }
    },

    cancelReq() {
      this.request.cancel();
      this.request = null;
    },

    openDelDialog(user) {
      this.userToDelete = user;
      this.delDial = true;
    },

    closeDelDialog() {
      this.userToDelete = null;
      this.delDial = false;
    },

    // async addOwnerTest() {
    //   for (let i = 0; i < this.allGroups.length; i += 1) {
    //     if (
    //       this.allGroups[i].principalDisplayName.includes('DAHU')
    //       || this.allGroups[i].principalDisplayName.includes('ENR')
    //       || this.allGroups[i].principalDisplayName.includes('ENF')
    //       || this.allGroups[i].principalDisplayName.includes('PARA')
    //     ) {
    //       try {
    //         // eslint-disable-next-line no-await-in-loop
    //         await this.$http.post(`https://graph.microsoft.com/v1.0/groups/${this.allGroups[i].principalId}/owners/$ref`, {
    //           '@odata.id': 'https://graph.microsoft.com/v1.0/users/userId',
    //         });
    //       } catch (error) {
    //         console.log(error);
    //       }
    //     }
    //   }
    // },
  },

  watch: {
    asyncSearch(val) {
      this.checkSearch(val);
    },
  },
};
</script>
